$primary: #33A6F3; // основной синий
$hover:   #0068B4; // основной синий при наведении
$primary-light: #DEF1FF; // основной лайтовый

$text: #242424; // основной текст

$color-input-label: #979797; // цвет лейблов у инпутов
$color-light: #e3e8ec;

$orange: #ff4f12;
$orange-hover: #ff612a;
$orange-light: #ff7700;
$pink: #ffbfc3;
$green: #69E0A0;
$red: #F86167;
$gray: #aaa;
$blue: #0068B4;

$gray-super-light: #f8f8f8;

$border-color: #e6e6e6;

/* fallback */
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v52/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// https://habr.com/ru/post/156645/
$mobile: 480px;
$desktop: 1024px;
$animation-duration: 0s;
$md: 767px;
$lg: 991px;

@mixin media($media) {
  @if $media == mobile {
    @media only screen and (max-width: $mobile) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $mobile + 1) and (max-width: $desktop - 1) {
      @content;
    }
  } @else if $media == desktop_minus {
    @media only screen and (max-width: $desktop) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $md) and (max-width: $desktop) {
      @content;
    }
  } @else if $media == medium_plus {
    @media only screen and (min-width: $mobile + 1) {
      @content;
    }
  } @else if $media == md {
    @media only screen and (min-width: $md + 1) {
      @content;
    }
  } @else if $media == md_minus {
    @media only screen and (max-width: $md) {
      @content;
    }
  } @else if $media == lg {
    @media only screen and (min-width: $lg + 1) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
}

@import '../sass/material-components-web.min.scss';
@import '../sass/sidebar.scss';
@import '../sass/forms.scss';
@import '../fonts/fonts.scss';
@import '../sass/spinner.scss';
@import '../sass/empty.scss';
@import '../sass/audio.scss';

.fz11 {
  font-size: 11px;
}
.fz12 {
  font-size: 12px;
}
.fz13 {
  font-size: 13px !important;
}
.fz14 {
  font-size: 14px !important;
}
.fz16 {
  font-size: 16px;
}
.fz18 {
  font-size: 18px !important;
}
.fz20 {
  font-size: 20px !important;
}
.fz24 {
  font-size: 24px;
}
.fz26 {
  font-size: 26px;
}
.fz37 {
  font-size: 37px;
}

.fw400 {
  font-weight: 400;
}
.fw500 {
  font-weight: 500;
}
.lh11 {
  line-height: 1.1em;
}
.lh14 {
  line-height: 1.4;
}
.lh20 {
  line-height: 2;
}

.wsnw {
  white-space: nowrap;
}
.vam {
  vertical-align: middle !important;
}
.vat {
  vertical-align: top !important;
}
.greedy {
  flex-grow: 2;
}

.border-orange {
  border-color: $orange !important;
}

.text-primary {
  color: $primary;
}
.text-color {
  color: $text;
}
.text-green {
  color: $green;
}
.text-gray {
  color: $gray;
}
.text-orange {
  color: $orange;
}
.text-orange-light {
  color: $orange-light;
}
.text-red {
  color: $red;
}

.text-muted {
  color: $gray!important;
}

.hover-primary {
  &:hover {
    color: $primary;
  }
}


.w-auto {
  width: auto !important;
}
.h-auto {
  height: auto !important;
}

.m-16 {
  margin-left: -16px;
  margin-right: -16px;
}

.mt-3, .my-3 {
  margin-top: 16px !important;
}

.mb-3, .my-3 {
  margin-bottom: 16px !important;
}

.mt-4, .my-4 {
  margin-top: 32px !important;
}

.mb-4, .my-4 {
  margin-bottom: 32px !important;
}

.mt-5, .my-5 {
  margin-top: 48px !important;
}

.mb-5, .my-5 {
  margin-bottom: 48px !important;
}

.ml-3, .mx-3 {
  margin-left: 16px !important;
}

.ml-4, .mx-4 {
  margin-left: 32px !important;
}

.mr-3, .mx-3 {
  margin-right: 16px !important;
}

.mr-4, .mx-4 {
  margin-right: 32px !important;
}


.pl-3, .px-3 {
  padding-left: 16px !important;
}

.pl-4, .px-4 {
  padding-left: 32px !important;
}

.pr-3, .px-3 {
  padding-right: 16px !important;
}

.pr-4, .px-4 {
  padding-right: 32px !important;
}


@include media(md) {
  .m-md-16 {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.ml--12 {
  margin-left: -12px;
}

.px-16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}


@include media(medium_plus) {
  .text-center-medium_plus {
    text-align: center;
  }
  .text-right-medium_plus {
    text-align: right;
  }
  .position-absolute-md {
    position: absolute;
  }
}

.top {
  top: 0;
}
.right {
  right: 0;
}

.pointer-events-none {
  pointer-events: none;
}

.block-disabled {
  pointer-events: none !important;
  opacity: 0.5 !important;

  & * {
    pointer-events: none !important;
  }
}

.grayscale {
  opacity: 0.4;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.cursor-pointer {
  cursor: pointer;
}

a {
  color: $primary;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: $blue;
  }
}

.btn {
  height: 48px;
  padding: 12px 32px;
  border-width: 2px;
  font-size: 13px;
  font-weight: 500;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    margin-right: 16px;

    &.icon-add-group {
      background-image: url(../../resources/img/add_group.svg);  
    }
    &.icon-add-phone {
      background-image: url(../img/add_phone.svg);  
    }
    &.icon-add-user {
      background-image: url(../img/add_user.svg);  
    }
    &.icon-user {
      background-image: url(../img/user.svg);  
    }    
    &.icon-users {
      background-image: url(../img/users.svg);  
    }
    &.icon-alarm {
      background-image: url(../img/alarm.svg);  
    }
    &.icon-envelope {
      background-image: url(../img/envelope.svg);  
    }
    &.icon-report {
      background-image: url(../img/report.svg);  
    }
    &.icon-wallet {
      background-image: url(../img/wallet.svg);  
    }
    &.icon-fax {
      background-image: url(../img/fax.svg);  
    }

  }

  &.btn-lg {
    padding: 13px 1rem 12px;
    font-size: 13px;
  }

  &.btn-sm {
    height: 24px;
    padding: 3px 16px;
    border: 1px solid $border-color;
    font-size: 11px;
    color: $primary;

    &:hover {
      color: #fff;
      background-color: $primary;
      border-color: $primary;
    }
  }

  &.btn-orange {
    color: #fff;
    background-color: $orange;
    border-color: $orange;

    &:focus,
    &:hover {
      background-color: $orange-hover;
      border-color: $orange-hover;
    }
  }

  &.btn-secondary {
    border-color: $primary;
    background-color: #fff;
    color: $text;

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled).active {
      border-color: $primary;
      background-color: $primary;
      color: #fff;      
    }

    &:hover {
      .icon-add-group {
        background-image: url(../img/add_group_white.svg);  
      }
      .icon-add-phone {
        background-image: url(../../../resources/img/add_phone_white.svg);  
      }
      .icon-add-user {
        background-image: url(../img/add_user_white.svg);  
      }
      .icon-user {
        background-image: url(../img/user_white.svg);  
      }
      .icon-users {
        background-image: url(../img/users_white.svg);  
      }
      .icon-alarm {
        background-image: url(../img/alarm_white.svg);  
      }
      .icon-envelope {
        background-image: url(../img/envelope_white.svg);  
      }
      .icon-report {
        background-image: url(../img/report_white.svg);  
      }
      .icon-wallet {
        background-image: url(../img/wallet_white.svg);  
      }
      .icon-fax {
        background-image: url(../img/fax_white.svg);  
      }
    }
  }
  
  &.btn-link {
    &.one-line {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.btn-disabled {
    border-color: #d8d8d8;
    background-color: #d8d8d8;
    color: #fff;
  }  
}

.btn-set {
  .btn {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    margin-bottom: 8px;
    padding: 13px 0;
  }

  .btn.active {
    font-weight: 500;
  }
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  color: inherit;
  font-weight: 600;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  color: inherit;
  font-weight: 500;
}

h1,
.h1 {
  font-size: 30px;
  margin-bottom: 32px;

  @include media(mobile) {
    font-size: 20px;
    line-height: 24px !important;
  }
}

h2,
.h2 {
  font-size: 22px;
  margin-bottom: 24px;
}

h3,
.h3 {
  font-size: 15px;
  margin-bottom: 16px;
}

h4,
h5,
.h4,
.h5 {
  font-size: 13px;
  margin-bottom: 12px;
}

p {
  line-height: 1.25;
}

.border-top,
.border-bottom {
  border-color: $border-color!important;
}

.tooltip {
  .tooltip-inner {
    background-color: #383e4c;
  }

  &.bs-tooltip-auto[x-placement^='top'] .arrow::before,
  &.bs-tooltip-top .arrow::before {
    border-top-color: #383e4c;
  }

  .bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
  .bs-tooltip-bottom .arrow::before {
    border-bottom-color: #383e4c;
  }

  &.show {
    opacity: 1;
  }
}

.rmwc-tooltip {
  &:not(.tooltip-player) {
    max-width: 200px;
  }

  .rmwc-tooltip-inner {
    background-color: #383e4c !important;
    font-size: 13px;
    text-align: center;
  }

  .rmwc-tooltip-arrow {
    border-top-color: #383e4c !important;
  }
}

.w-100-m {
  @include media(mobile) {
    width: 100% !important;
  }
}

.table-block {
  overflow: hidden;

  .table-stats {
    padding: 10px 0;
    font-size: 11px;
    color: $gray;
  }
}

.table {
  color: $text;

  thead tr th,
  .thead .th {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    font-weight: 500;
    font-size: 11px;
    color: $gray;
  }

  tr, .tr  {
    td, .td {
      padding: 16px 16px;
      border-color: $border-color;
      font-size: 11px;
  
      &.with-icon {
        width: 40px;
        padding-right: 0 !important;
      }
    }

    &:last-child {
      td, .td {
        border-bottom: 1px solid $border-color;
      }
    }

    &.border-bottom-0 {
      td,
      th,
      .td,
      .th {
        border-bottom: none;
      }
    }

    &.border-top-0 {
      td,
      th,
      .td,
      .th {
        border-top: none;
      }
    }

    &.px-0 {
      td:first-child,
      th:first-child,
      .td:first-child,
      .th:first-child {
        padding-left: 0;
      }
      td:last-child,
      th:last-child,
      .td:last-child,
      .th:last-child {
        padding-right: 0;
      }
    }
  }

  &.p24 tr td,
  &.p24 .tr .td {
    @include media(medium_plus) {
      &:first-child {
        padding-left: 24px;
      }
    }
  }

  .icons {
    display: inline-block;
    padding-left: 5px;

    img {
      margin-left: 5px;
    }
  }

  .one-line {
    position: relative;
    max-width: 100%;
    padding-top: 24px;

    .text-overflow {
      position: absolute;
      top: 0;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .table-row-title {
    background-color: $gray-super-light;
    padding: 0 !important;

    td,
    .td {
      position: relative;
      padding: 0 16px;
      vertical-align: middle;
      height: 72px;
      border-bottom: 1px solid $border-color;

      .header {
        display: flex;
        max-width: 400px;

        a.title {
          font-weight: 600;
          font-size: 15px;
        }

        a.with-icon {
          span.icon {
            vertical-align: middle;
          }

          &:first-child {

            span.icon {
              width: 16px;
              height: 16px;
              margin-right: 16px;

              &.arrow-down {
                background-image: url(../img/arr_down_16.svg);
              }
              &.arrow-right {
                background-image: url(../img/arr_right_16.svg);
              }
              
            }
          }
        }
      }

      .sub {
        padding-left: 34px;
      }

      @include media(mobile) {
        display: table-cell;
        height: 56px;
      }
    }
  }

  .table-row-highlighted {
    background-color: #f8f9fa;

    td,
    .td {
      border-bottom: transparent;
      @include media(medium_plus) {
        position: relative;
      }

      .link {
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $gray;
        top: 0;
        left: 7px;

        &:before {
          content: '';
          position: absolute;
          top: 24px;
          width: 8px;
          height: 1px;
          background-color: $gray;
        }
      }
    }

    &.td-bordered td,
    &.td-bordered .td {
      border-bottom: 1px solid $color-light;

      .link {
        height: 24px;
      }
    }
  }

  .with-checkbox {
    display: flex;

    .mdc-form-field {
      position: relative;
      top: -8px;
      margin-left: -12px;
      margin-right: 10px;
      align-items: start;
    }

    &.align-items-center {
      .mdc-form-field {
        position: static;
      }
    }
  }

  th .with-checkbox,
  .th .with-checkbox {
    align-items: baseline;
    margin-top: -28px;

    .mdc-form-field {
      top: 15px;
    }
  }

  .collapse.show {
    display: table-row-group;
  }

  @include media(mobile) {
    tbody td {
      position: relative;
    }
    tbody td:first-child:before,
    tbody td.last-mobile:before {
      content: '';
      position: absolute;
      bottom: -1px;
      height: 1px;
      width: 16px;
      background-color: #fff;
    }

    tbody td:first-child:before {
      left: 0;
    }
    tbody td.last-mobile:before {
      right: 0;
    }
  }
}

div.table {
  display: table;
  
  .tr {
    display: table-row;

    &.disabled .td > *:not(.opacity1) {
      opacity: 0.5;
    }
  }

  .td,
  .th {
    display: table-cell;
    padding: 16px;
    vertical-align: top;
  }

  .th {
    font-weight: 500;
    vertical-align: bottom;
  }

  &.p16 .td,
  &.p16 .th {
    padding: 16px 0.75rem;
  }

  @include media(mobile) {
    :not(.table-row-title) .td {
      margin-bottom: 10px;
    }
    .p-checkbox {
      padding-left: 30px;
    }
    .row.p-checkbox {
      padding-left: 15px;
    }
  }
}

table {
  &.vat {
    td,
    th {
      vertical-align: top;
    }
  }

  &.p16 {
    td,
    th {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  &.p8 {
    td,
    th {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  th {
    font-weight: 500;
  }
}

.marked-list {
  .list-name {
    position: relative;
    width: 100%;
    padding-left: 14px;

    .marked {
      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        display: inline-block;
        width: 6px;
        height: 6px;
        background-color: #fff;
        border-color: $green;
        border-style: solid;
        border-width: 4px;
        border-radius: 4px;
      }

      &.hollow:before {
        border-width: 2px;
        width: 8px;
        height: 8px;
      }

      &.primary:before {
        border-color: $primary;
      }

      &.red:before {
        border-color: $red;
      }

      &.yellow:before {
        border-color: #ffbe08;        
      }

      &.purple:before {
        border-color: #bc104b;
      }

      &.pink:before {
        border-color: #ffbfc3;        
      }

      &.orange:before {
        border-color: #ff4f12;        
      }

      &.d-blue:before {
        border-color: #273a64;        
      }

      &.disabled:before,
      &.gray:before {
        border-color: $gray;
      }
    }

    &.fz11 {
      padding-left: 12px;

      .marked {
        &:before {
          top: 3px;
        }  
      }      
    }
  }

  &.table {
    .list-name {
      width: auto;
      padding-left: 28px;

      span.marked {
        &:before {
          top: 18px;
          left: 16px;
        }
      }
    }
  }
}

span.with-icon,
a.with-icon,
div.with-icon,
button.with-icon {
  img {
    position: relative;
    top: -2px;
    margin-top: 2px;
    margin-right: 5px;
  }

  &.h5,
  &.w24 {
    img {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .icon {
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    &.icon-delete {
      width: 16px;
      height: 16px;
      background-image: url(../img/delete.svg);
    }
    &.icon-download {
      width: 24px;
      height: 24px;
      background-image: url(../img/download.svg);
    }
    &.icon-edit {
      width: 16px;
      height: 16px;
      background-image: url(../img/edit.svg);
    }
    &.icon-add-to-group {
      width: 16px;
      height: 16px;
      background-image: url(../img/add_to_group.svg);
    }
  }

  span.text {
    color: $text;
    line-height: 24px;
  }

  .material-icons {
    position: relative;
    vertical-align: bottom;
  }

  &:hover {
    .icon {
      &.icon-delete {
        background-image: url(../img/delete_primary.svg);
      }
      &.icon-edit {
        background-image: url(../img/edit_primary.svg);
      }
      &.icon-add-to-group {
        background-image: url(../img/add_to_group_primary.svg);
      }
    }
  }
}

a {

}

a.with-icon:hover,
span.with-icon:hover {
  text-decoration: none;

  span.text {
    color: $hover;
  }
}

.tag {
  max-width: 100%;
  padding: 2px 10px;
  border-radius: 16px;
  border: 1px solid;
  margin-right: 12px;
  margin-bottom: 8px;
  line-height: 30px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
  }

  &.tag-gray {
    background-color: $border-color;
    border-color: $border-color;
  }

  &.tag-orange {
    background-color: $orange;
    border-color: $orange;
    color: white;
  }

  &.tag-primary {
    background-color: #fff;
    border-color: $primary;
    color: $primary;

    &.active {
      background-color: $primary;
      color: white;
    }
  }

  &.tag-sm {
    padding: 0 6px;
    margin-right: 8px;
    font-size: 12px;
    line-height: 12px;
  }

  .tag-close {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-left: 3px;
    background-image: url(../img/delete.svg);
    cursor: pointer;
  }

  &.with-icon {
    position: relative;
    padding-right: 30px;

    .icon {
      position: absolute;
      top: 3px;
      right: 7px;
      width: 16px;
      height: 16px;
      margin-right: 0;
    }
  }
}

.tags {
  .tag {
    display: inline-block;
    margin-right: 8px;
    line-height: 26px;
    white-space: normal;
    overflow-wrap: break-word;    
  }
}

.sfui {
  font-family: 'SF UI Text Regular';
}

body {
  font-family: 'Inter';
  font-size: 13px;
  color: $text;
}

#root {
  min-width: 767px;
}

#prewrapper {
  &.fullscreen {
    #sidebar-wrapper,
    header.navbar,
    .fullscreen-hide {
      display: none;
    }

    #page-content-wrapper {
      width: 100%!important;
      margin-left: 0!important;
    }
  }

  &.svetofon {
    #sidebar-wrapper,
    header.navbar  {
      display: none;
    }

    #page-content-wrapper {
      width: 100%!important;
      margin-left: 0!important;
    }
  }
}

#wrapper {
  display: flex;

  &.no-sidebar {
    
    #page-content-wrapper {
      width: 100%!important;
      margin-left: 0!important;
    }

    .navbar-light {
      .brand-wrap {
        display: flex;
        align-items: center;
        width: 232px;
        height: 64px;
        border-right: 1px solid #005899;
        margin-right: 32px;        

        .brand {
          display: inline-block;
          min-height: 32px;
          background-image: url(../img/logo_graph_64.png);
          background-repeat: no-repeat;
          background-size: 64px 26px;
          background-position-y: 4px;
          padding-top: 6px;
          padding-left: 78px;
          color: #fff;
          

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}



.navbar-light {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
  height: 64px;
  flex-wrap: nowrap;
  padding: 0 32px;
  background-color: #0068B4;
  border-left: 1px solid #005899;
  color: #fff;

  .brand-wrap {
    display: none;
  }

  .left {
    display: flex;
    align-items: center;

    .server-info {
    }

    .balance-info {
      position: relative;
      white-space: nowrap;
      color: #fff;

      &:hover {
        text-decoration: none;
        color: inherit;
      }

      .badge {
        position: absolute;
        top: -8px;
        right: -16px;
        width: 16px;
        height: 16px;
        padding: 0;
        min-width: auto;
        border-radius: 8px;
        background-color: $red;
        background-image: url(../img/lock-white.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    margin-left: auto;
    padding-right: 5px;

    .user {
      

      .user-name {
        font-weight: 500;
        color: #fff;
      }

      .user-type {
        color: $gray;
      }
    }


    .navbar-toggler {
      padding: 0;
      border: none;
      margin-left: 16px;

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
      }
    }
  }

  .dropdown-holder {
    display: flex;
    align-items: center;
    position: relative;
    height: 64px;
    padding: 0 56px 0 32px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 32px;
      width: 8px;
      height: 8px;
      background-image: url(../img/arr_small_down.svg);
      background-repeat: no-repeat;
    }

    .dropdown {
      display: none;
      position: absolute;
      top: 64px;
      right: 0;
      background-color: #0068B4;
      border-top: 1px solid #fff;
      padding: 16px 32px;

      ul.nav {
        display: block;

        li {
          margin-bottom: 16px;

          a {
            color: #fff; 

            &:hover {
              color: $primary;
              text-decoration: none;
            }
          }  

          &:last-child {
            margin-bottom: 0;
          }
        }
        
      }
    }

    &:hover {
      background-color: rgba(24,24,24, .15);      

      .dropdown {
        display: block;
      }
    }
  }
}

main {
  min-height: calc(100vh - 64px - 32px); // -header -copyright
  padding: 0 32px 32px;
  border-left: 1px solid $border-color;
}

.content {
  padding: 32px 0 24px;

  .title {
    position: relative;

    h1 {
      .status {
        vertical-align: middle;
        margin-left: 12px;
        font-size: 14px;
        font-weight: normal;

        &.online:before {
          content: '';
          position: relative;
          top: 3px;
          width: 16px;
          height: 16px;
          background-color: #57d9b7;
          border-radius: 8px;
          display: inline-block;
          margin-right: 6px;
        }
      }
    }

    .back {
      margin-bottom: 28px;
    }

    .meta {
      /* margin-bottom: 24px; */
    }
  }
}



.card-block {
  height: 100%;
  padding: 20px;
  border: 1px solid $border-color;
  background-color: #fff;

  &.p16 {
    padding: 16px;
  }

  &.gray {
    background-color: $gray-super-light;
    border: none;
  }

  .title {
    margin-bottom: 20px;

    .icon.help {
      vertical-align: top;

      @include media(mobile) {
        display: none;
      }
    }
  }
}

.filter {
  margin-bottom: 32px;  

  .filter-bottom {
    font-size: 11px;

    a, span.link {
      display: inline-block;
      margin-right: 8px;

      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }
}

.filter-tags {
  margin-bottom: 32px;
}

.text-field-settled {
  min-height: 48px;
  flex-direction: row;
  align-items: center;

  label {
    display: block;
    margin-bottom: 0;
    color: $gray;
    font-size: 12px;
  }
}

.icon.help.material-icons {
  position: relative;
  top: 2px;
  color: #b5b5b5;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.icon.help:not(.material-icons),
.icon.info:not(.material-icons),
.icon.calendar:not(.material-icons),
.icon.clock:not(.material-icons) {
  &:not(.mdc-text-field__icon) {
    position: relative;
    top: 2px;
  }
  
  width: 16px;
  height: 16px;
  display: inline-block;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;

  &.help {
    width: 17px;
    background-image: url(../img/help.svg);
    margin-left: 2px;

    &.text-muted {
      background-image: url(../img/help_gray.svg);
    }
  }
  &.info {
    width: 16px;
    background-image: url(../img/info_16_gray.svg);
    margin-left: 2px;
  }
  &.calendar {
    background-image: url(../img/calendar.svg);
  }
  &.clock {
    background-image: url(../img/clock.svg);
  }
  &:hover {
    text-decoration: none;
  }
}

h1 .icon {
  top: 0px !important;
}

// MODAL
.modal,
.mdc-dialog {
  .modal-dialog,
  .mdc-dialog__container {
    max-width: 610px;

    &.modal-sm {
      max-width: 320px;
    }
    &.modal-md {
      max-width: 424px;
    }
    &.modal-lg {
      max-width: 800px;
    }
    &.modal-xl {
      max-width: 1000px;
    }

    @include media(mobile) {
      width: auto !important;
    }

    .modal-content,
    .mdc-dialog__surface {
      border-radius: 0;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.56);

      .modal-header {
        position: relative;
        border-bottom: none;
        padding: 8px 52px 8px 16px;

        .modal-paging {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          .left,
          .right {
            width: 20%;
          }

          .page {
            font-size: 24px;
            color: #737781;
          }
        }

        .modal-title {
          overflow: hidden;
          padding-right: 16px;
          
          .modal-title-sub {
            font-size: 16px;
            font-weight: normal;
          }
        }

        .back {
          margin-bottom: 4px;
        }
      }

      &.p24 .modal-header .back {
        margin-top: -8px;
      }

      .close {
        position: absolute;
        top: 26px;
        right: 26px;
        width: 24px;
        height: 24px;
        opacity: 1;
        background-image: url(../img/cross.svg);
        background-repeat: no-repeat;
        background-position: center;
        color: white; // temp hack
      }

      @include media(medium_plus) {
        .modal-header.h1-align .close {
          top: 32px;
          right: 32px;
        }

        .modal-header.h4-align .close {
          top: 32px;
        }

        &.p24 .modal-header.h1-align .close {
          top: 40px;
        }
      }

      .modal-body {
        padding: 16px 16px;
        overflow-wrap: break-word;
      }

      .modal-footer {
        justify-content: flex-start;

        .btn {
          margin-right: 16px;
        }

        .p-checkbox {
          padding-left: 40px;
        }

        & > :not(:first-child) {
          margin-left: 0;
        }
      }

      @include media(medium_plus) {
        &.p24 {
          .modal-body {
            padding: 24px;
          }
          .modal-header {
            padding-top: 24px;
            padding-bottom: 24px;
            padding-left: 24px;
          }
        }
      }
    }

    &.modal-banner {
      .modal-content {
        background-image: linear-gradient(117deg, #ffbfc3 62%, #fff 38%);
        padding: 16px 16px 24px;
        border: none;

        .close {
          top: 12px;
          right: 12px;
        }

        .left {
          position: relative;
          display: inline-block;
          width: 64%;
          padding: 2px 0 0;
          border-left: 8px solid #fff;
          font-weight: 600;

          .marked {
            background-color: #fff;
            display: inline;
            padding: 4px 0 3px;

            .indent {
              position: relative;
              left: -4px;

              .heading {
                color: $text;
              }
            }
          }
        }

        .modal-footer {
          border: none;
          padding: 0;
          justify-content: space-between;
          margin-top: 24px;

          .btn-link {
            font-weight: bold;
            color: $primary;
          }
        }
      }
    }
  }
}
.modal-backdrop {
  background-color: #2d2d2d;
}
.menu-toggled .modal-backdrop {
  z-index: 99;
}

.mdc-dialog {
  z-index: 1050;
  max-width: 100%;

  &.modal-sm .mdc-dialog__container {
    max-width: 320px;
  }
  &.modal-md .mdc-dialog__container {
    max-width: 424px;
  }
  &.modal-lg .mdc-dialog__container {
    max-width: 800px;
  }
  &.modal-xl .mdc-dialog__container {
    max-width: 1000px;
  }

  .mdc-dialog__container {
    display: block;
    max-width: 610px;
    margin: 1.75rem auto;

    .mdc-dialog__surface {
      max-width: 100%;
      max-height: none;
      overflow: visible;

      .mdc-dialog__content {
        padding: 1rem;
        overflow: visible;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        letter-spacing: inherit;
        font-weight: inherit;
      }
    }
  }

  &.mdc-dialog--open,
  &.mdc-dialog--opening,
  &.mdc-dialog--closing {
    display: block;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.banner {
  display: flex;

  &.banner-lg {
    height: 104px;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(117deg, $pink 75%, #fff 25%);
    padding: 10px 24px 10px 32px;
    margin-bottom: 24px;

    &.banner-lg .d-flex {
      align-items: center;
      flex-grow: 2;
    }

    img {
      width: 56px;
      height: 56px;
    }

    p.marked {
      width: 416px;
      max-width: 55%;
      background-color: #fff;
      padding: 0 8px;
      margin-bottom: 0;

      font-weight: 500;
      font-size: 18px;
      line-height: 1.4em;
    }

    .more a {
      font-size: 16px;
      font-weight: 500;
    }

    &.banner-lg-reverse {
      background-image: linear-gradient(145deg, #fff 55%, #ffbfc3 45%);

      p.marked {
        background: none;
        width: auto;
        min-width: 90%;
      }

      .more a {
        font-size: 14px;
        font-weight: normal;
      }
    }

    &.banner-orange {
      background-image: linear-gradient(144deg, #fff 55%, $orange 45%);

      p.marked {
        color: #fff;
        background-color: $primary;
      }

      .right {
        justify-content: flex-end;
        color: white;
      }
    }
  }

  &.banner-sm {
    max-width: 100%;

    .inner {
      position: relative;
      width: 100%;
      padding-bottom: 26%; // to keep aspect ratio
      background-image: linear-gradient(165.5deg, $pink 50%, $gray 50%);

      .left {
        position: absolute;
        top: 8px;
        left: 8px;
        display: inline-block;
        width: 45%;
        padding: 3px 0 1px;
        border-left: 26px solid $primary;

        .marked {
          background-color: $primary;
          display: inline;
          padding: 6px 0 4px;

          .indent {
            position: relative;
            left: -15px;

            .heading {
              color: #fff;
              font-size: 16px;
            }
          }
        }
      }

      .bottom {
        position: absolute;
        right: 8px;
        bottom: 8px;
        width: 45%;
        font-size: 12px;
      }
    }
  }
}

.items-list {
  padding-left: 0;

  li.with-icon {
    padding-left: 64px;
    min-height: 48px;
    margin-bottom: 20px;
    background-position: left top;
    background-repeat: no-repeat;
    list-style: none;

    &.icon-calltracking {
      background-image: url(../img/service/calltracking.svg);
    }
    &.icon-integration-api {
      background-image: url(../img/service/integration_api.svg);
    }
    &.icon-virtual-contact-center {
      background-image: url(../img/service/virtual_contact_center.svg);
    }
    &.icon-voice-record {
      background-image: url(../img/service/voice_record.svg);
    }
    &.icon-users {
      background-image: url(../img/service/users.svg);
    }
    &.icon-integration {
      background-image: url(../img/service/integration.svg);
    }
    &.icon-additional-numbers {
      background-image: url(../img/service/additional_numbers.svg);
    }
    &.icon-virtual-ats {
      background-image: url(../img/service/virtual_ats.svg);
    }
    &.icon-auto_call {
      background-image: url(../img/service/auto_call.svg);
    }
    &.icon-callback {
      background-image: url(../img/service/callback.svg);
    }
    &.icon-voice_scenario {
      background-image: url(../img/service/voice_scenario.svg);
    }
    &.icon-ivr_scenario {
      background-image: url(../img/service/ivr_scenario.svg);
    }

    .item-name {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

.services-nav {
  .service {
    text-align: center;

    a {
      display: inline-block;
      vertical-align: top;
      background-repeat: no-repeat;
      background-position: center top;
      padding-top: 54px;
      background-size: auto 48px;
    }

    &.icon-widgets a {
      background-image: url(../img/service/widgets.svg);
    }
    &.icon-callback a {
      background-image: url(../img/service/callback.svg);
    }
    &.icon-ivr a {
      background-image: url(../img/service/voice_scenario.svg);
    }
    &.icon-aicall a {
      background-image: url(../img/service/auto_call.svg);
    }
    &.icon-outgoing_campaign a {
      background-image: url(../img/service/outgoing_campaign.svg);
    }
    &.icon-voice_record a {
      background-image: url(../img/service/voice_record.svg);
    }
    &.icon-integration_api a {
      background-image: url(../img/service/integration_api.svg);
    }
    &.icon-integration a {
      background-image: url(../img/service/integration.svg);
    }
    &.icon-private_route a {
      background-image: url(../img/service/private_route.svg);
      background-size: auto 42px;
    }

    &.disabled {
      color: #6c757d !important;

      &.icon-widgets a {
        background-image: url(../img/service/widgets_disabled.svg);
      }
      &.icon-callback a {
        background-image: url(../img/service/callback_disabled.svg);
      }
      &.icon-ivr a {
        background-image: url(../img/service/voice_scenario_disabled.svg);
      }
      &.icon-aicall a {
        background-image: url(../img/service/auto_call_disabled.svg);
      }
      &.icon-outgoing_campaign a {
        background-image: url(../img/service/outgoing_campaign_disabled.svg);
      }
      &.icon-voice_record a {
        background-image: url(../img/service/voice_record_disabled.svg);
      }
      &.icon-integration_api a {
        background-image: url(../img/service/integration_api_disabled.svg);
      }
      &.icon-integration a {
        background-image: url(../img/service/integration_disabled.svg);
      }
      &.icon-private_route a {
        background-image: url(../img/service/private_route_disabled.svg);
        background-size: auto 42px;
      }
    }

    img {
      margin-bottom: 4px;
    }

    .service-name {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
}

.article {
  .article-body {
    .img {
      background-color: $color-light; // temp
      height: 150px;
      margin-bottom: 1rem;
    }
  }
}

.articles-list {
  .article-item {
    padding: 24px 24px;
    border-bottom: 1px solid $border-color;

    &:first-child {
      border-top: 1px solid $border-color;
    }

    &.unread {
      background-color: #f8f9fa;
    }

    .article-title {
      margin-bottom: 32px;

      h5 {
        margin-bottom: 14px;
      }

      .meta {
      }

      .tag-gray {
        background-color: #eaedf1;
      }
    }

    .article-body {
    }

    &.with-icon {
      padding-top: 30px;
      padding-left: 64px;
      background-size: 48px 48px;
      background-position: left top 16px;
      background-repeat: no-repeat;

      &.icon-integration-api {
        background-image: url(../img/service/integration_api.svg);
      }
      &.icon-virtual-contact-center {
        background-image: url(../img/service/virtual_contact_center.svg);
      }
      &.icon-voice-record {
        background-image: url(../img/service/voice_record.svg);
      }
      &.icon-users {
        background-image: url(../img/service/users.svg);
      }
      &.icon-integration {
        background-image: url(../img/service/integration.svg);
      }
      &.icon-additional-numbers {
        background-image: url(../img/service/additional_numbers.svg);
      }
      &.icon-virtual-ats {
        background-image: url(../img/service/virtual_ats.svg);
      }
      &.icon-auto_call {
        background-image: url(../img/service/auto_call.svg);
      }
      &.icon-callback {
        background-image: url(../img/service/callback.svg);
      }
      &.icon-voice_scenario {
        background-image: url(../img/service/voice_scenario.svg);
      }
      &.icon-ivr_scenario {
        background-image: url(../img/service/ivr_scenario.svg);
      }

      .article-title {
        margin-bottom: 24px;
      }

      @include media(mobile) {
        background-position: center top 16px;
        padding: 80px 16px 16px;
      }
    }
  }

  &.p16 {
    .article-item {
      padding: 16px 16px;
    }

    .article-title {
      margin-bottom: 16px;
    }
  }
}

.info-block {
  min-height: 32px;
  background-image: url(../img/info.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 32px 32px;
  padding-left: 50px;
  margin-bottom: 16px;
  font-size: 12px;

  &.info-orange {
    background-image: url(../img/info_orange_small.svg?v2);
  }

  &.notice {
    background-image: url(../img/info_notice.svg);
  }

  &.check {
    background-image: url(../img/info_check.svg);
  }

  &.info-big {
    min-height: 46px;
    background-size: 46px 46px;
    padding-left: 54px;
  }

  &.info-border {
    border-width: 1px; 
    border-style: solid;
    border-radius: 3px;
    border-color: $border-color;
    padding: 16px 16px 16px 64px;
    background-position: left 16px top 16px;
    min-height: 64px;

    &.info-border-orange {
      border-color: #FFA556;
    }

    .title {
      margin-right: 16px;
      font-size: 15px;
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.play {
  min-height: 48px;
  background-image: url(../img/play_big.svg);
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 48px 48px;
  padding-left: 56px;
}

.list-icons-inline {
  ul {
    padding-left: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      display: inline-block;
      vertical-align: top;
      background-repeat: no-repeat;
      margin-right: 16px;
      margin-bottom: 8px;
      background-size: 24px 24px;
      min-height: 24px;
      padding-top: 2px;

      &.folder {
        padding-left: 22px;
        background-image: url(../img/folder.svg);
        background-size: 16px 16px;
        background-position: left top 3px;
      }

      &.group {
        &.with-delete {
          position: relative;
          margin-bottom: 0;
          padding-right: 20px;

          a {
           
          }

          .delete {
            position: absolute;
            top: 8px;
            right: 4px;
            display: inline-block;
            width: 8px;
            height: 8px;
            background-image: url(../img/cross_8.svg);
            background-size: 8px 8px;

            &:hover {
              background-image: url(../img/cross_8_primary.svg);
            }
          }

          &:hover {
            a {
              color: $hover;
            }
          }
        }
      }
      &.arrow {
        background-image: url(../img/arrow_right.svg);
      }
      &.shapes {
        background-image: url(../img/shapes.svg);
      }
      &.delete {
        background-image: url(../img/delete.svg);
      }
      &.support {
        background-image: url(../img/icon_support.svg);
        background-size: 24px 27px;
      }
      &.play {
        background-image: url(../img/play_small.svg);
      }
    }
  }

  &.one-line {
    position: relative;
    max-width: 100%;
    padding-top: 24px;

    ul {
      position: absolute;
      top: 0;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

ul.numbers {
  padding-left: 0;
  list-style: none;

  li {
    display: flex;
    margin-bottom: 20px;

    .number {
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      border: 2px solid #cfd1d4;
      border-radius: 30px;
      padding: 6px;
      margin-right: 15px;

      text-align: center;
      color: #cfd1d4;
      font-size: 24px;
    }

    .content {
      padding-top: 4px;
      line-height: 1.4em;
    }
  }

  &.line {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 1px);
      width: 100%;
      height: 1px;
      background-color: #cfd1d4;
    }

    li {
      position: relative;
      z-index: 1;
      background-color: #fff;
      margin-bottom: 0;

      .number {
        margin-right: 0;
      }
    }
  }
}

ul.list1 {
  li {
    margin-bottom: 12px;
  }
}

ul.two-columns {
  columns: 2;
  list-style: none;

  &.two-columns-lg {
    columns: 1;

    @include media(lg) {
      columns: 2;
    }
  }

  &.width-limit {
    li {
      max-width: 200px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 8px;
    }
  }
}

.success-block {
  border-top: 1px solid $green;
  border-bottom: 1px solid $green;
  padding: 24px 24px;
  margin-bottom: 30px;

  .title {
    font-size: 16px;
  }
}

.warning-block {
  border-top: 1px solid $orange;
  border-bottom: 1px solid $orange;
  padding: 24px 24px;
  margin-bottom: 30px;
}

.value-big {
  font-size: 26px;
}

.scroll-268 {
  height: 268px;
  max-height: 268px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-336 {
  height: 336px;
  max-height: 336px;
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.scroll-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

// TEMP
.capcha img,
.range img,
.graph img {
  max-width: 100%;
}

.route-block {
  margin-right: 6px;
  margin-bottom: 40px;

  .title {
    background-position: left center;
    background-repeat: no-repeat;
    background-image: url(../img/phone_circle.svg);
    padding-left: 40px;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
    line-height: 33px;
  }

  .route-block-body {
    display: inline-block;
    vertical-align: top;
    margin-left: 16px;

    .route-row {
      display: flex;

      .item {
        position: relative;
        min-width: 165px;
        text-align: center;
        padding: 24px 24px 0px;

        .name {
          line-height: 24px;
        }

        .value {
          font-size: 37px;
          font-weight: 300;
        }

        &:first-child {
          border-left: 1px solid $border-color;

          &:before {
            content: '';
            position: absolute;
            top: 30px;
            left: -25px;
            width: 19px;
            height: 11px;
            background-repeat: no-repeat;
            background-size: 19px 11px;
          }

          &.in:before {
            background-image: url(../img/arr_right.svg);
          }
          &.out:before {
            background-image: url(../img/arr_left.svg);
          }
        }

        @include media(mobile) {
          min-width: auto;
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }
}

table.modal-list {
  td:first-child,
  td:nth-child(2) {
    padding-right: 0;
  }

  td:nth-child(2) {
    padding-left: 8px;
  }
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .item {
    display: flex;
    padding: 16px 24px;
    border: 1px solid $border-color;
    margin-top: -1px;
    margin-left: -1px;

    .mdc-form-field {
      position: relative;
      top: -8px;
    }

    .after-checkbox {
      padding-left: 4px;
    }

    &.with-checkbox {
      padding-left: 8px;
    }
  }

  @include media(lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.stripe-gray {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  background-color: #f8f9fa;
  margin-bottom: 24px;

  .left {
    margin-right: 24px;
  }

  .one-line {
    flex-grow: 2;
    position: relative;
    max-width: 100%;

    .text-overflow {
      position: absolute;
      top: -10px;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      .item {
        margin-right: 24px;
      }
    }
  }

  .right {
    margin-left: 24px;
  }
}

.captcha-img-wrapper {
  text-align: center;

  .captcha-img {
    display: inline-block;
    cursor: pointer;
    margin: 5px 0;
  }

  .text {
    margin-top: 4px;
    font-size: 12px;
    color: $gray;
    line-height: 14px;
  }
}

.loading-overlay {
  position: fixed; // при absolute длинные экраны не перекрываются
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  background-color: #ffffffba;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: wait;
}

.progress {
  position: relative;
  background-color: #fff;
  width: 100%;
  height: 40px;
  border: 1px solid rgba(60, 0, 128, 0.125);

  .progress-bar {
    &.grey {
      height: 40px;
      background-color: #e3e8ec;
    }

    &.progress-bar-animated-alt {
      position: absolute;
      top: 7px;
      height: 24px;
    }
  }
}

.linked-tag {
  @extend .tag;

  &:hover,
  &.selected {
    background-color: var(--mdc-theme-primary);
    color: var(--mdc-theme-text-primary-on-dark);
    border-color: var(--mdc-theme-primary);
  }
}

.transition-bg-03 {
  transition: background-color 0.3s ease-in-out;
}

.loader-block {
  display: inline-block;
  min-width: 80px;
  background: #00000042;
  border-radius: .2em;
  height: 1em;
  animation: blink 1.2s ease-in-out infinite
}

@keyframes blink {
  0%,100% {
    background: #00000014;
  }

  50% {
    background: #0000003b;
  }
}

// схема переадресации вызова
.redirection-scheme {
  overflow-x: auto;
  display: flex;
  align-items: center;
  padding: 60px 0;

  .group {
    position: relative;
    display: flex;
    align-items: center;
  }

  .number-block {
    position: relative;
    top: 16px;
  }

  .item {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $green;
    border-radius: 5px;
    padding: 8px 16px;
    background-color: #fff;
    cursor: pointer;

    &.disabled {
      border-color: $border-color;
    }

    &.number {      
      z-index: 1;
      padding: 20px 16px;
    }

    &.timeout {
      z-index: 2;
      top: -16px;
      display: inline-block;
      margin: 0 6px;
      padding: 6px;
      border-color: $border-color;
      font-size: 10px;
    }
  }

  .text {
    
  }

  img + .text {
    margin-left: 12px;      
  }

  

  .add-block {
    position: absolute;
    top: -9px;
    width: 60px;
    text-align: center;
    cursor: pointer;

    .add-circle {        
      display: inline-block;
      position: relative;
      top: -1px;
      width: 22px;
      height: 22px;
      border-radius: 24px;
      border: 1px solid $green;
      background-color: #fff;
      margin-bottom: 5px;
      transition: .2s;

      .text {
        line-height: 15px;
        color: $green;
        font-size: 30px;
        font-weight: 100;
      }

      &:hover {
        background-color: $green;

        .text {
          color: #fff;
        }
      }
    }  

    .text {
      line-height: 16px;
    }
  }


  .line {
    position: relative;
    width: 25px;
    min-width: 25px;
    height: 1px;
    background-color: $green;

    &.w-60 {
      width: 60px;
    }

    &.with-arrow:after {
      content: '';
      position: absolute;
      right: -1px;
      top: -4px;
      width: 0px;
      height: 0px;
      border-top: 4px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 7px solid $green;
    }
  }

  .timeswitch {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 22px;
    cursor: pointer;

    .timeswitch-rectangle {
      position: absolute;
      width: 32px;
      height: 32px;
      border: 1px solid $green;
      transform: rotate(45deg);  
      background-color: #fff;      
    }

    img {
      position: absolute;
      z-index: 1;
    }
  }


  .nodes {
    position: relative;
    display: flex;
    flex-direction: column;

    .branch {
      position: relative;
      display: flex;
      justify-content: space-between;
      height: 100px;

      // branch line
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        height: 1px;
        width: 100%;
        background-color: $green;
      }

      &.disabled:after {
        background-color: $gray; 
      }

      .item.number:before {
        content: '';
        position: absolute;
        top: 29px;
        left: -7px;
        width: 0px;
        height: 0px;
        border-top: 4px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 7px solid $green;
      }

      &.disabled .item.number:before {
        border-left-color: $gray;
      }

      .side {
        display: flex;
        align-items: flex-end;
        min-width: 90px;
        padding: 0 16px 0 26px;

        &.right {
          min-width: 16px;
        }

        &:first-child:before,
        &:last-child:before {
          content: '';
          position: absolute;
          top: 50px;
          width: 1px;
          height: 100px;
          background-color: $green;
        }

        &:first-child:before {
          left: 0;
        }

        &:last-child:before {
          right: 0;
        }

        .schedule-text {
          position: relative;
          top: -54px;
          line-height: 13px;
          font-size: 10px;          
          color: $gray;
        }
      }

      &.lower {
        .side:first-child:before,
        .side:last-child:before {
          top: auto;
          bottom: 50px;
        }

        &.lower-first .side:first-child:before,
        &.lower-first .side:last-child:before {
          height: 50px;
        }

        &.middle .side:first-child:before,
        &.middle .side:last-child:before {
          display: none;
        }
      }

      &.disabled {
        .side:first-child:before, 
        .side:last-child:before {
          background-color: $gray;
        }
      }
    }
  }

  .number-block,
  .nodes {
    .add-block  {
      top: 0;
      left: 50%;
      opacity: 0;
      margin-left: -30px;
      padding-bottom: 5px;
      transition: 0.3s;
    }

    &:hover {
      .add-block {
        top: -20px;
        opacity: 1;
      }
    }
  }  
}


#chartjs-tooltip {
  @extend .shadow;
  line-height: normal;
  padding: 5px;
  position: fixed;
  transform: translate(-55%, -150%);
  background-color: #FFFFFFd4;
  border-radius: 3px;
  //color: $text;

  .tooltip-line-color {
    margin-right: 4px;
    width: 1em;
    height: 1em;
  }
}

// схема маршрутизации
.route-rules-scheme {
  display: flex;
  flex-direction: row;
  padding-left: 32px;
  padding-bottom: 80px;
  margin-left: 11px;
  border-left: 1px solid $border-color;


  .order-number {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    width: 23px;
    height: 23px;    
    border: 1px solid $border-color;
    background-color: white;
    margin-left: -12px;
    margin-right: 8px;
    border-radius: 12px;

    font-weight: 500;    
    color: $primary;

    &:hover {
      background-color: $primary-light;
      color: transparent;
      background-image: url(../img/sort.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.copyright {
  height: 32px;
  text-align: right;
  font-size: 11px;
  padding: 0 32px;
  color: $gray; 
  border-left: 1px solid $border-color;
}

.scroll-y-200 {
  overflow-y: auto;
  max-height: 200px;
}
 
.min-h-100 {
  min-height: 100px;
}
.min-w-300 {
  min-width: 300px;
}
 
/* Tagify style for outside mode */
 
.tagify--outside{
  border: 0;
}
 
.tagify--outside .tagify__input{
  order: -1;
  flex: 100%;
  border: 1px solid var(--tags-border-color);
  margin-bottom: 1em;
  transition: .1s;
}
 
.tagify--outside .tagify__input:hover{ border-color:var(--tags-hover-border-color); }
.tagify--outside.tagify--focus .tagify__input{
  transition:0s;
  border-color: var(--tags-focus-border-color);
}