.ccrv-crossed-line {
  text-decoration: line-through;
}

.ccrv-legend-text {
  font-size: 17px;
  display: inline-block;
  vertical-align: middle;
  cursor: default;
}

.ccrv-border-block {
  border: 2px solid lightgray;
  border-radius: 3px;
}

.ccrv-relative-position-base {
  position: relative; 
  width: 100%;
}

.ccrv-chart-half-page {
  @extend .ccrv-relative-position-base;
  height: 46vh;
}

.ccrv-chart-one-third-page {
  @extend .ccrv-relative-position-base;
  height: 34vh;
}

.ccrv-chart-quarter-page {
  @extend .ccrv-relative-position-base;
  height: 23vh;
}

.ccrv-chart-fifth-page {
  @extend .ccrv-relative-position-base;
  height: 19vh;
}

.ccrv-single-chart-page {
  @extend .ccrv-relative-position-base;
  height: 56vh;
}

.ccrv-chart-full-page {
  @extend .ccrv-relative-position-base;
  height: 92vh;
}

.rsc-legend-overflow-labels-row {
  position: absolute;
  overflow-y: auto;
  height: 80%;
  top: 20%;
}

.rsc-legend-overflow-title {
  position: absolute;
  height: 20%;
}