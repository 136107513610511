// https://startbootstrap.com/previews/simple-sidebar/
#page-content-wrapper {
  min-width: 100vw;
  min-height: 100vh;

  @include media(desktop) {
    min-width: 0;
    width: calc(100% - 264px);
    margin-left: 264px;
  }

  @include media(desktop_minus) {
    min-width: 0;
    width: calc(100% - 64px);
    margin-left: 64px;
  }
}

#sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 101;
  width: 264px;
  height: 100vh;
  max-height: calc(100vh);
  background-color: #fff;
  transition: 0.25s ease-out;

  .brand-wrap {
    position: relative;
    height: 64px;
    padding: 15px 15px 15px 24px;
    background-color: #0068B4;

    .brand {
      display: inline-block;
      min-height: 32px;
      background-image: url(../img/logo_graph_64.png);
      background-repeat: no-repeat;
      background-size: 64px 26px;
      background-position-y: 4px;
      padding-top: 6px;
      padding-left: 78px;

      color: #fff;
      font-size: 13px;

      &:hover {
        text-decoration: none;
      }

      .text {
        @include media(desktop_minus) {
          display: none;
        }
      }

      @include media(desktop_minus) {
        background-size: 30px 13px;
        background-position-y: 10px;
      }
    }

    @include media(desktop_minus) {
      padding-left: 16px;
    }
  }

  .nav {
    flex-wrap: nowrap;
    padding-top: 7px;
    height: calc(100vh - 64px - 42px);
    overflow-y: auto;
  }

  .sidebar-footer {
    height: 42px;
    padding: 12px 16px 0 40px;
    color: $gray;

    a {
      color: $gray;

      .icon {
        margin-right: 16px;

        @include media(desktop_minus) {
          margin-right: 0;
        } 
      }
    }    
    
    @include media(desktop_minus) {
      padding: 0 16px;
      text-align: center;

      .text {
        display: none;
      }
    }
  }

  @include media(desktop_minus) {
    width: 64px;    
  } 

}

.nav.sidebar-nav {

  & > li {
    position: relative;
    margin: 0;

    .submenu-toggle {
      position: absolute;
      top: 0;
      right: 0;
      height: 50px;
      width: 40px;
      background-image: url(../img/arr_down.svg);
      background-repeat: no-repeat;
      background-position: center right 16px;
      cursor: pointer;

      &.active {
        background-image: url(../img/arr_up.svg);
      }

      &.fullwidth {
        width: 100%;
      }

      @include media(desktop_minus) {
        display: none;
      }
    }

    & > a {
      display: block;
      padding: 16px 16px 16px 40px;

      font-weight: 500;
      color: $text;

      &:hover,
      &.active {
        text-decoration: none;
        background-color: #DEF1FF;
      }

      .fa {
        width: 16px;
        height: 16px;
        display: inline-block;
        vertical-align: top;
        background-size: 16px 16px;
        margin-right: 20px;
        background-repeat: no-repeat;
        background-position: left;

        @include media(desktop_minus) {
          display: block;
          margin: auto
        }
      }

      .fa-icon-user {
        background-image: url(../img/user.svg);
      }
      .fa-icon-calls-settings {
        background-image: url(../img/menu/settings.svg);
      }
      .fa-icon-calls-voicemail {
        background-image: url(../img/menu/calls_voicemail.svg);
      }
      .fa-icon-fax {
        background-image: url(../img/menu/fax.svg);
      }
      .fa-icon-address-book {
        background-image: url(../img/menu/address_book.svg);
      }
      .fa-icon-dashboard {
        background-image: url(../img/menu/dashboard.svg);
      }
      .fa-icon-users {
        background-image: url(../img/user.svg);
      }
      .fa-icon-users-groups {
        background-image: url(../img/users.svg);
      }
      .fa-icon-history {
        background-image: url(../img/menu/history.svg);
      }
      .fa-icon-services {
        background-image: url(../img/menu/services.svg);
      }
      .fa-icon-routing {
        background-image: url(../img/menu/phone.svg);
      }
      .fa-icon-links {
        background-image: url(../img/menu/links.svg);
      }
      .fa-icon-voicemail {
        background-image: url(../img/menu/voicemail.svg);
      }
      .fa-icon-folder {
        background-image: url(../img/menu/folder.svg);
      }
      .fa-icon-user-1 {
        background-image: url(../img/menu/user_1.svg);
      }
      .fa-icon-phone-down {
        background-image: url(../img/menu/phone_down.svg);
      }
      .fa-icon-random-number {
        background-image: url(../img/random_number.svg);
      }
      .fa-icon-address-finance {
        background-image: url(../img/menu/finance.svg);
      }

      .text {
        @include media(desktop_minus) {
          display: none;
        }
      }

      .sub {
        display: block;
        font-size: 12px;
        font-weight: normal;
      }

      span.text-muted {
        font-weight: normal;
      }

      @include media(desktop_minus) {
        padding: 16px;
      }
    }

    .sub-nav {
      margin-left: 50px;
      padding-left: 0;
      list-style: none;
      border-left: 1px solid $border-color;

      li {
        list-style: none;

        a {
          color: $gray;
          padding: 8px 16px 8px 20px;
          border-left: 4px solid transparent;


          &:hover,
          &.active {
            color: $text;
            border-left-color: #DEF1FF;
          }
        }
      }

      @include media(desktop_minus) {
        display: none;
      }
    }
  }
}

.menu-toggled #sidebar-wrapper {
  left: 0;
}
